<template>
  <div>

    <span role="button"
          style="position: absolute; zoom: 1.5"
    >
      <feather-icon
        icon="InfoIcon"
        class="text-warning"
        @click="lessonIdToShowDetail = lessonInfo?.id"
      />
      &nbsp;
    </span>
    <b-breadcrumb
      class="ml-1"
    >
      <b-breadcrumb-item v-for="(item, index) in breadcrumbItems"
                         :key="index"
                         :href="`${item.href}&library=${lessonInfo.library_id}`"
                         :active="item.active"
      >
        {{ item.text }}
      </b-breadcrumb-item>
    </b-breadcrumb>
    <b-col class="search-results">
      {{ $t('student-report-module.problem-table.total') }}  <strong class="text-bold">{{ problemRows.length }}</strong> {{ $t('labels.problems') }}
    </b-col>
    <b-col
      sm="12"
      class="d-flex justify-content-between p-0 mt-2"
    >

      <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary mx-2"
          @click="GO_BACK()"
        >
          {{ $t("actions.back") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary mr-2"
          @click="showLessonPreview = true"
        >
          {{ $t("actions.lesson-demo") }}
        </b-button>
        <b-button
          v-if="!isSDPLesson"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="$router.push({ path: `/${auth.usertype}/problem/create`, query: { lesson_id: $route.query.lesson_id } })"
        >
          {{ $t("actions.create-problem") }}
        </b-button>
        <b-button
          v-if="!isSDPLesson"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary ml-2"
          @click="showProblemAutomation = true"
        >
          {{ $t("actions.automate-problem") }}
        </b-button>
      </div>
    </b-col>
    <div class="my-2">
      <b-row class="ml-1">
        <b-col class="col-md-2 d-flex">
          <div v-if="isGeneratedByCB">
            <div class="media pr-4">
              <div class="media-aside mr-1 align-self-start">
                <b-button
                  variant="outline-success"
                  class="btn-icon rounded-circle"
                  @click="standardView"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
              </div>
              <div class="media-body my-auto">
                <p class="card-text font-small-3 mb-0">
                  Group View
                </p>
              </div>
            </div>
            <div
              v-if="!validateSkillCount"
              class="media"
            >
              <div class="media-aside mr-1 align-self-start">
                <b-button
                  variant="outline-warning"
                  class="btn-icon rounded-circle"
                  @click="showFault"
                >
                  <feather-icon icon="AlertCircleIcon" />
                </b-button>
              </div>
              <div class="media-body my-auto">
                <p class="card-text font-small-3 mb-0">
                  Show Hints
                </p>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-md-3"
        >
          <label style="margin-right:10px; margin-top: 10px; font-size: 14px;">Filter:</label>
          <v-select
            v-model="problemFilter"
            :clearable="false"
            label="label"
            :options="filterList"
            :reduce="option => option.value"
            class="w-100"
            @input="fetchProblem()"
          />
        </b-col>
        <b-col
          class="col-md-7 d-flex"
        >
          <div
            v-for="skill,index of skillsCount"
            :key="index"
            class="media px-1 d-block"
          >
            <div class="media-aside align-self-start pb-1">
              <b-button
                variant="outline-primary"
                class="btn-icon rounded-circle"
                @click="fetchProblem(skill.skill_type, skill.problem_type)"
              >
                <feather-icon :icon="skill.icon" />
              </b-button>
            </div>
            <div class="media-body">
              <h4 class="font-weight-bolder mb-0">
                {{ skill.total }}
              </h4><p class="card-text font-small-3 mb-0">
                {{ skill.name }}
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-if="showStandardView">
      <standard-view :data="standardProblemList" />
    </div>
    <div v-else>
      <GroupTable
        :columns="problemColumns"
        :rows="problemRows"
        :lesson-info="lessonInfo"
        :is-s-d-p-lesson="isSDPLesson"
        @fetchProblem="fetchProblem"
        @deleted="fetchProblem"
      />
    </div>

    <create-game
      :toggle="isGameCreate"
      :lesson-id="$route.query.lesson_id"
      :columns="problemColumnForGame"
      :rows="problemRows.filter(p => !p.hasVideoWrapper && !p.hasHotSpotWrapper)"
      @close="isGameCreate = false"
      @created="fetchProblem"
      @updated="fetchProblem"
    />
    <lesson-preview
      :show="showLessonPreview"
      :lesson-id="Number($route.query.lesson_id)"
      :lesson-info="lessonInfo"
      @close="showLessonPreview = false"
    />

    <b-modal
      ref="modal-detect-problem"
      title="Problem Detector"
      :visible="show"
      hide-footer
    >
      <ul v-if="detectedProblems.length > 0">
        <li
          v-for="problem in detectedProblems"
          :key="problem.id"
        >
          {{ problem.id+" "+ problem.name+ " "+ problem.skill_type }}
        </li>
      </ul>
      <p
        v-else
        class="text-danger"
      >
        <feather-icon icon="AlertCircleIcon" />
        System Detect An Issue In Problem List.
      </p>
      <p>If it is generated from Curriculum Builder. These 4 skills count must be equal, otherwise it will not work for weakest skill algorithm.</p>
      <p>If it is Not generated from Curriculum Builder, It is not the problem with unequal number of skills</p>
      <p class="bg-info p-1">
        <b>Hints & Solution:</b> If problems are generated by CB, you need to update from CB to get equal portion of skills
      </p>
    </b-modal>
    <lesson-detail-model
      :show="!!lessonIdToShowDetail"
      :lesson-id="lessonIdToShowDetail"
      @close="lessonIdToShowDetail=0"
    />
    <ProblemBatchUpload :show="showBatchUpload"
                        @close="showBatchUpload = false"
    />
    <ProblemAutomation :show="showProblemAutomation"
                       :lesson-id="lessonId"
                       @close="showProblemAutomation = false"
                       @refetch="fetchProblem"
    />
  </div>
</template>

<script>
import {
  BCol,
  BButton,
  VBToggle,
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { getUserData } from '@/auth/utils'
import LessonDetailModel from '@/views/common/components/LessonDetailModel.vue'
import GroupTable from './GroupTable.vue'
import CreateGame from './components/createGame.vue'
import LessonPreview from './components/LessonPreview.vue'
import StandardView from './StandardView.vue'
import ProblemBatchUpload from './components/ProblemBatchUpload.vue'
import ProblemAutomation from './components/ProblemAutomation.vue'

export default {
  components: {
    BCol,
    BButton,
    GroupTable,
    CreateGame,
    BBreadcrumb,
    LessonPreview,
    BRow,
    BModal,
    StandardView,
    LessonDetailModel,
    ProblemBatchUpload,
    BBreadcrumbItem,
    ProblemAutomation,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      detectedProblems: [],
      breadcrumbItems: null,
      problemColumnForGame: [
        {
          label: '',
          field: 'checkbox',
        },
        {
          label: i18n.tc('Name'),
          field: 'name',
        },
        {
          label: i18n.tc('Type'),
          field: 'problem_type',
        },
      ],
      problemRows: [],
      standards: [],
      problemType: {
        matching: i18n.tc('problem-types.match-the-following'),
        blank: i18n.tc('problem-types.fill-in-the-blanks'),
        fib_dropdown: i18n.tc('problem-types.fib-dropdown'),
        multiple: i18n.tc('problem-types.multiple-choice'),
        speech: i18n.tc('problem-types.speech'),
        text_selection: i18n.tc('problem-types.text-selection'),
        matching_listening: i18n.tc('problem-types.matching-listening'),
        multiple_listening: i18n.tc('problem-types.multiple-choice-listening'),
        drag_position: i18n.tc('problem-types.drag-position'),
      },
      isGameCreate: false,
      lessonInfo: null,
      show: false,
      auth: getUserData(),
      showLessonPreview: false,
      problemList: [],
      standardProblemList: [],
      showStandardView: false,
      isGeneratedByCB: false,
      lessonIdToShowDetail: 0,
      showBatchUpload: false,
      problemFilter: 'regular',
      filterList: [
        { value: 'regular', label: 'Regular' },
        { value: 'TGP', label: 'Teacher Generated Problem' },
        { value: 'MGP', label: 'Machine Generated Problem' },
      ],
      showProblemAutomation: false,
    }
  },
  computed: {
    problemColumns() {
      return [
        {
          label: '',
          field: 'selectRow',
        },
        {
          label: '#',
          field: '#',
        },
        {
          label: i18n.tc('Name'),
          field: 'name',
        },
        {
          label: i18n.tc('Type'),
          field: 'problem_type',
        },
        {
          label: i18n.tc('Tags'),
          field: 'tags',
        },
        {
          label: i18n.tc('Wrapper'),
          field: 'wrapper',
        },
        {
          label: i18n.tc('DDF'),
          field: 'ddf_set',
        },
        {
          label: i18n.tc('actions.action'),
          field: 'action',
          category: false,
          thClass: 'text-center',
        },
      ]
    },
    validateSkillCount() {
      const skillCountArr = this.skillsCount
      const result = skillCountArr.every(v => v.total === skillCountArr[0].total)
      return result
    },
    skillsCount() {
      return [
        {
          name: 'Reading',
          icon: 'BookOpenIcon',
          total: this.problemList.filter(item => item.skill_type === 'reading').length,
          skill_type: 'reading',
          problem_type: null,
        },
        {
          name: 'Listening',
          icon: 'MessageCircleIcon',
          total: this.problemList.filter(item => item.skill_type === 'listening').length,
          skill_type: 'listening',
          problem_type: null,
        },
        {
          name: 'P-Speaking',
          icon: 'Volume2Icon',
          total: this.problemList.filter(item => item.skill_type === 'pronunciation').length,
          skill_type: 'speaking',
          problem_type: 'Speech',
        },
        {
          name: 'OE-Speaking',
          icon: 'Volume2Icon',
          total: this.problemList.filter(item => item.skill_type === 'speaking' && item.problem_type?.name === 'open-end-writing-speech').length,
          skill_type: 'speaking',
          problem_type: 'open-end-writing-speech',
        },
        {
          name: 'P-Writing',
          icon: 'PenToolIcon',
          total: this.problemList.filter(item => item.skill_type === 'typing').length,
          skill_type: 'writing',
          problem_type: 'blank',
        },
        {
          name: 'OE-Writing',
          icon: 'PenToolIcon',
          total: this.problemList.filter(item => item.skill_type === 'writing' && item.problem_type?.name === 'open-end-writing').length,
          skill_type: 'writing',
          problem_type: 'open-end-writing',
        },
      ]
    },
    isSDPLesson() {
      return this.lessonInfo?.type?.name === 'SDP'
    },
    lessonId() {
      return Number(this.$route.query.lesson_id)
    },
  },
  created() {
    this.$store.commit('problem/SHOW_ERROR', false)
    this.fetchProblem()
    useJwt.getDomainByLesson(this.$route.query.lesson_id)
      .then(res => {
        this.lessonInfo = res.data.data
        this.breadcrumbItems = [
          {
            text: res.data.data.name,
            href: `/${this.auth.usertype}/courses/?lesson_group_id=${res.data.data.lesson_group_id}`,
            isLink: true,
          },
          {
            text: i18n.tc('actions.problem'),
            active: true,
          },
        ]
      })
  },
  methods: {
    showFault() {
      useJwt.showFaultInCB(this.$route.query.lesson_id).then(res => {
        this.$refs['modal-detect-problem'].show()
        this.detectedProblems = res.data.data
      })
    },
    addProblemsTable(problem, sets) {
      this.problemRows.push({
        name: problem.name,
        problem_type: this.problemType[problem.problem_type.name] ? this.problemType[problem.problem_type.name] : problem.problem_type.name,
        standard: problem.problem_set.standard ? problem.problem_set.standard.name : null,
        id: problem.id,
        tags: problem.tags,
        ddf_set: problem.ddf_set,
        is_public: problem.is_public === 1,
        hasGameWrapper: !!problem.game.length,
        hasVideoWrapper: !!problem.video_timestamp_id,
        hasHotSpotWrapper: !!problem.hotspot_point,
        sdp_problem_id: problem.sdp_problem_id,
        set_name: sets.find(set => set.problem_id === problem.id)?.set?.name,
        set_id: sets.find(set => set.problem_id === problem.id)?.set_id,
        order_index: problem.order_index,
        setInfo: sets.find(set => set.problem_id === problem.id)?.set,
        skill_type: problem.skill_type,
      })
    },
    fetchProblem(skillType = null, problemType = null) {
      this.problemRows = []
      useJwt.getProblemSetWrapperLists(this.$route.query.lesson_id).then(response => {
        const sets = response.data.data
        useJwt.getProblemByLesson({
          params: {
            lid: this.$route.query.lesson_id,
            generated_type: this.problemFilter,
            skill_type: skillType,
            problem_type: problemType,
          },
        }).then(res => {
          this.problemList = res.data.data.data
          this.isGeneratedByCB = res.data.data.is_generated_by_cb
          res.data.data.data.forEach(problem => {
            this.addProblemsTable(problem, sets)
          })
        })
      })
    },
    standardView() {
      const group = this.groupByKey(this.problemList, 'group_key')
      this.standardProblemList = Object.entries(group).map(item => ({
        group_name: item[0],
        reading: item[1]?.filter(problem => problem.skill_type === 'reading') || [],
        writing: item[1]?.filter(problem => problem.skill_type === 'writing') || [],
        speaking: item[1]?.filter(problem => problem.skill_type === 'speaking') || [],
        listening: item[1]?.filter(problem => problem.skill_type === 'listening') || [],
      }))

      this.showStandardView = !this.showStandardView
    },
    // filterBySkillType(skillType, problemType) {
    //   console.log(skillType, problemType)
    // },
  },
}
</script>
